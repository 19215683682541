import './App.css';
 
import { useState, useEffect, useRef } from "react";
import { useMediaQuery } from 'react-responsive'
import Footer from './components/Footer';
import { Analytics } from '@vercel/analytics/react';

// import GA4React from "ga-4-react";

// const ga4react = new GA4React("G-MDNS7V7JFP");
// ga4react.initialize().then().catch();

function App() {
  const [classSubNav, setClassSubNav] = useState("hidden lg:block");
  const [isScrollFarFromTop, setIsScrollFarFromTop] = useState(false);
  const nguonNuoc = useRef(null);
  const veVictory = useRef(null);
  const sanPham = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 30) {
        setIsScrollFarFromTop(true);
      } else {
        setIsScrollFarFromTop(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleToogleSubNav = () => {
    setClassSubNav(
      classSubNav === "hidden lg:block" ? "block lg:block" : "hidden lg:block"
    );
  };
  return (
    <div className="App">
      <div className='w-full max-w-[1000px] mx-auto'>
        <nav>
          <div
            className={`fixed w-full max-w-[1000px] px-6 h-[100px] flex items-center justify-between bg-[#00b1ba] ${isScrollFarFromTop ? "lg:bg-[#00b1ba]" : "lg:bg-transparent"
              } z-10`}
          >
            <a className="lg:hidden" href="/">
              <img src="/images/logo.webp" alt="logo" style={{ width: "120px" }} />
            </a>

            <button
              onClick={handleToogleSubNav}
              data-collapse-toggle="navbar-sticky"
              type="button"
              class="lg:hidden items-center p-2 text-sm"
              aria-controls="navbar-sticky"
              aria-expanded="false"
            >
              <span class="sr-only">Open main menu</span>
              <svg
                class="w-6 h-6"
                aria-hidden="true"
                fill="white"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>

            <div
              className={`${classSubNav} absolute left-0 top-[100px] lg:relative lg:top-0 w-full lg:flex items-center justify-around bg-white text-black lg:text-white lg:bg-inherit`}
            >
              <div className="py-7 border-b border-gray-300 lg:py-0">
                <a
                  onClick={() => {
                    handleToogleSubNav();
                    veVictory.current.scrollIntoView({ behavior: "smooth" });
                  }}
                  href="#victory"
                  className="text-lg font-medium"
                >
                  VỀ VICTORY
                </a>
              </div>

              <div className="py-7 border-b border-gray-300 lg:py-0">
                <a
                  onClick={() => {
                    handleToogleSubNav();
                    nguonNuoc.current.scrollIntoView({ behavior: "smooth" });
                  }}
                  href="#nguon-nuoc"
                  className="text-lg font-medium"
                >
                  NGUỒN NƯỚC
                </a>
              </div>

              <div>
                <a href="/" className="hidden lg:block">
                  <img
                    src="/images/logo.webp"
                    alt="logo"
                    style={{ width: "120px" }}
                  />
                </a>
              </div>

              <div className="py-7 border-b border-gray-300 lg:py-0">
                <a
                  onClick={() => {
                    handleToogleSubNav();
                    sanPham.current.scrollIntoView({ behavior: "smooth" });
                  }}
                  href="#san-pham"
                  className="text-lg font-medium"
                >
                  SẢN PHẨM
                </a>
              </div>

              <div className="py-7 border-b border-gray-300 lg:py-0">
                <a
                  onClick={handleToogleSubNav}
                  href="#lien-he"
                  className="text-lg font-medium"
                >
                  LIÊN HỆ ĐẶT HÀNG
                </a>
              </div>

              <div className="py-7 lg:py-0 lg:hidden">
                <a
                  href="tel:0945989096"
                  className="text-white bg-[#E91C35] rounded py-3 px-7"
                >
                  Hotline: 0945989096
                </a>
              </div>
            </div>
          </div>

          <div className="h-[100px] lg:hidden"></div>
        </nav>

        <div>
          <img src='./images/top-banner.webp'></img>
        </div>

        <div className='relative'>
          <img src='./images/introduction-final.webp' className='w-full'></img>
          <div className='absolute top-[-10%] md:top-0' ref={veVictory} ></div>
          <div className='absolute top-0 md:top-[40%]' ref={nguonNuoc} ></div>
          <div className='absolute bottom-[100%] md:bottom-[40%]' ref={sanPham} ></div>
        </div>

        <div id='lien-he' style={{
          position: 'relative',
          backgroundImage: `url("/images/bn.webp")`,
        }} className="bg-cover h-[400px] lg:h-[500px] bg-bottom w-full">
          <div className='absolute h-full w-full text-white flex justify-left items-center text-[30px] lg:text-[50px]'>
            <div className='text-left pl-[20px] sm:pl-[50px]'>
              <div className='border-b-4 mb-4 border-[#E91C35]'>LIÊN HỆ ĐẶT HÀNG</div>
              <a href="tel:0945989096" className='bg-[#E91C35] p-2'>Hotline: 0945989096</a>
            </div>
          </div>
          <div className='fixed right-5 bottom-5'>
            <a href="tel:0945989096" type="button" aria-label="call-fixed" className="mb-2 h-[60px] w-[60px] rounded-full bg-[#E91C35] z-20 flex items-center justify-center">
              <i className="fa-solid fa-phone text-[25px] text-white animation-shake"></i></a>

            <div className='bottom-5 right-5 h-[60px] w-[60px] rounded'>
              <a href='https://zalo.me/0945989096' target='_blank'><img src={'/images/zalo.png'} alt='zalo' style={{ width: '100%', height: '100%' }} /></a>
            </div>
          </div>

        </div>

        <Footer />

        <Analytics />
      </div>

      
    </div>
  );
}

export default App;
