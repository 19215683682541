import React from "react";

export default function Footer() {
  return (
    <footer class="p-4 bg-white shadow md:px-6 md:py-8">
      <div class="">
        <a class="flex items-center justify-center mb-4 sm:mb-0">
          <img src="/images/logo.webp" class="h-[100px]" alt="logo" />
        </a>
        <div className="">
          <div>
            <div className="mb-4">CÔNG TY CỔ PHẦN THẮNG LỢI BÌNH DƯƠNG</div>
            <div className="mb-4">
              Địa chỉ: Đường số 28, Khu TĐC Định Hòa, TP. Thủ Dầu
              Một, Bình Dương, Việt Nam
            </div>
          </div>
          <div className="border-b border-gray-400 mb-4"></div>
          <div className="text-center">
            <div className="text-center md:flex space-x-4 justify-center">
              <div className="mb-4">Mã số thuế: 3700716852</div>
              <div className="mb-4">
                <a href="tel:02743822551" className="mb-4">
                  Số Điện Thoại: 0274.3822.551
                </a>
              </div>
              <div className="mb-4">Fax: 0274.3829.371</div>
            </div>
            <div>
              <div className="border-b border-gray-400 mb-4"></div>
              <div>
                <div className="mb-4">
                  <a href="tel:0945989096" className="">
                    Hotline: 0945.989.096
                  </a>
                </div>
                <div className="mb-4">
                  <a href="mailto:victory@victorywater.vn" className="mb-4">
                    Email: victory@victorywater.vn
                  </a>
                </div>
                <div className="mb-4">
                  <a href="mailto:tuyendung@victorywater.vn" className="mb-4">
                    Email tuyển dụng: tuyendung@victorywater.vn
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="my-6 border-gray-400 sm:mx-auto" />
      <span class="block text-sm text-gray-700 sm:text-center">
        <div className="py-2 text-red-600">
          <a href="/" class="hover:underline">
            www.victorywater.vn
          </a>
        </div>
        <span className="mr-1">© {new Date().getFullYear()}</span>. All Rights
        Reserved.
      </span>
    </footer>
  );
}
